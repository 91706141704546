<template>
  <div>
    <b-card>
      <div class="w-100 my-1 mb-2 float-left pl-1">
        <div class="d-flex align-items-center float-left">
          <a class="mr-50" @click="$router.go(-1)">
            <feather-icon icon="ChevronLeftIcon" size="20" />
          </a>
          <h3 class="m-0 font-weight-600">
            {{ appTitle || $t("successstories.title") }}
          </h3>
        </div>
        <b-button
          v-if="canCreate"
          v-b-modal.modal-create-product
          class="float-right mr-1"
          variant="outline-primary"
        >
          {{ $t("successstories.create") }}
        </b-button>
      </div>

      <!-- Loading -->
      <div v-if="isLoading">
        <b-spinner
          type="grow"
          small
          class="text-primary mx-auto mt-3 d-block"
        />
      </div>

      <!-- Container with data -->
      <div v-else-if="items.length > 0" class="cards-container">
        <div>
          <!-- Items Loop -->
          <b-card
            v-for="(item, index) in items"
            :key="index"
            v-show="canList(item)"
            class="float-left mx-1 mb-2 p-0 card-standard card-container"
          >
            <b-link :to="{ name: 'successstory', params: { id: item.key } }">
              <b-img
                :alt="`${item.name}`"
                fluid
                :src="getImageSrc(item.banner) || Placeholder"
                class="card-image-medium"
              />
              <!-- <b-badge
                v-if="item.createdAt"
                class="my-1"
                variant="light-primary"
              >
                {{ timestampToTime(item.createdAt) }}
              </b-badge> -->
              <h4 class="mt-1 mb-75 min-height-3">
                {{ item.name }}
              </h4>
              <span
                v-if="item.headline"
                class="min-height-3 html-text-ellipsis-2"
                v-html="item.headline"
              />
              <span
                v-if="item.description"
                class="min-height-3 html-text-ellipsis-2 content-container"
                v-html="item.description"
              />
              <div v-else class="min-height-3" />
              <b-card-text class="w-100 mt-2 text-muted">
                <span class="icon-container">
                  <feather-icon
                    size="20"
                    :class="
                      item.likedByMember ? 'profile-likes' : 'profile-icon'
                    "
                    icon="HeartIcon"
                    class="mr-25"
                    @click.stop.prevent="toggleLike(item)"
                  />
                  {{ item.totalLikes }}
                </span>
              </b-card-text>
            </b-link>
          </b-card>
        </div>

        <!-- Load More items -->
        <div
          v-if="items.length < itemsData.meta.total"
          class="w-100 float-left"
        >
          <b-button
            v-if="!isLoadingNextPage"
            class="center-x my-3"
            variant="outline-primary"
            @click="handleLoadOfNewItems"
          >
            {{ $t("action.load-more") }}
          </b-button>
          <div v-else class="w-100">
            <b-spinner
              class="center-x my-3"
              small
              variant="primary"
              type="grow"
              label="Loading..."
            />
          </div>
        </div>
      </div>

      <!-- Container without data: Placeholder -->
      <b-row v-else class="horizontal-placeholder">
        <b-col cols="12">
          <img :src="SuccessPlaceholder" />
          <p class="text-primary">
            {{ $t("successstories.placeholder") }}
          </p>
        </b-col>
      </b-row>
    </b-card>

    <!-- Create Product -->
    <b-modal
      id="modal-create-product"
      :title="$t('successstories.modal-create-title')"
      :ok-title="$t('form.actions.save')"
      :cancel-title="$t('form-create-item.cancel')"
      cancel-variant="outline-secondary"
      centered
      size="lg"
      @ok="handleCreateItem"
    >
      <b-form>
        <b-form-group>
          <label for="name">{{ $t("form-create-item.title") }}:</label>
          <b-form-input
            id="name"
            v-model="productInput.name"
            type="text"
            :state="formError"
          />
        </b-form-group>
        <b-form-group>
          <label class="mt-2" for="headline">{{
            $t("form-create-item.headline")
          }}</label>
          <b-form-input v-model="productInput.headline" type="text" />
        </b-form-group>
        <b-form-group>
          <label class="mt-2" for="description"
            >{{ $t("form-create-item.description") }}:</label
          >
          <quill-editor v-model="productInput.description" />
        </b-form-group>

        <b-col color="12" md="6">
          <b-form-group label="Banner" label-for="banner">
            <file-upload
              v-model="itemImage"
              type="image"
              cancelable
              :placeholder="$t('form-create-item.image')"
              :drop-placeholder="$t('form-create-item.drop-placeholder')"
              @cancel="imageSrc = null"
            />
          </b-form-group>
        </b-col>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import Placeholder from "@/assets/images/placeholders/light/placeholder-dark.jpeg";
import SuccessPlaceholder from "@/assets/images/placeholders/light/success_stories.svg";
import { ENABLED_APPS_GETTERS } from "@/store/enabled-apps/enabled-apps-store-constants";
import { getImageResource } from "@/@core/utils/image-utils";
import { quillEditor } from "vue-quill-editor";
import FileTypes from "@/@core/constants/FileTypes";

import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
// import Teleport from "vue2-teleport";
import { checkPermissions } from "@/@core/utils/roles-utils";
// import RequestMeetingModal from "../../member/modals/RequestMeetingModal.vue";
import FileUpload from "@core/components/files/FileUpload.vue";
// import { AppIDLUT } from "@copernicsw/community-common";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";

export default {
  name: "SuccessStoriesList",
  components: {
    quillEditor,
    FileUpload,
  },
  data() {
    return {
      isLoading: true,
      isLoadingNextPage: false,
      lastLoadedPage: 1,
      loadingNext: false,
      memberForMeeting: {},
      isRequestMeetingVisible: false,
      productInput: {},
      formError: null,
      itemImage: null,
      imageSrc: null,
      SuccessPlaceholder,
    };
  },
  computed: {
    FileTypes() {
      return FileTypes;
    },
    // Load data from store
    itemsData() {
      return this.$store.getters.successstories;
    },
    items() {
      return this.itemsData.unpaginated;
    },
    hasMeetingsSlotsAddOn() {
      return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps]
        .meetingsSlots;
    },
    hasMeetingAddOn() {
      return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps].meetings;
    },
    hasChatIndividualAddOn() {
      return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps]
        .chatIndividual;
    },
    Placeholder() {
      return Placeholder;
    },
    isStaff() {
      return this.$store.getters.currentCollective.isStaff;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    canCreate() {
      return checkPermissions(
        "create",
        "success-story",
        this.loggedMemberRoles,
        this.collective
      );
    },

    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    appId() {
      return 75;
    },
    app() {
      return this.$store.getters.apps.apps[this.appId];
    },
    appTitle() {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        this.app?.customizationName
      );
    },
  },
  watch: {
    itemImage(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
  },
  // Fetch data when component is created!
  async created() {
    this.isLoading = true;
    await this.fetchData();
    await this.updateBreadcrumbs();
    this.isLoading = false;
  },
  methods: {
    canList(item) {
      return (
        this.loggedUser.key === item.OwnedByUser?.key ||
        this.isStaff ||
        checkPermissions(
          "index",
          "success-story",
          this.loggedMemberRoles,
          this.$store.getters.currentCollective
        )
      );
    },
    // TODO: move to utils
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    // Call store action (with dispach) to load data from backend
    getImageSrc(url) {
      return getImageResource(url);
    },
    async fetchData() {
      await this.$store.dispatch("getItems", {
        itemType: "successstories",
        page: this.lastLoadedPage,
        requestConfig: {
          count: 16,
          orderByDate: -1,
        },
      });
      this.updateBreadcrumbs();
      this.isLoading = false;
    },

    // Load more items
    async handleLoadOfNewItems() {
      const { total } = this.itemsData.meta;
      if (!this.isLoading && this.items.length < total) {
        this.lastLoadedPage += 1;
        this.isLoadingNextPage = true;
        await this.fetchData();
        this.isLoadingNextPage = false;
      }
    },
    timestampToTime(timestamp) {
      return new Date(timestamp).toLocaleTimeString([], {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    async toggleLike(item) {
      await this.$store.dispatch("toggleLike", {
        itemType: "successstories",
        morphType: "successstories",
        key: item.key,
      });
    },
    async handleCreateItem(bvModalEvt) {
      // Name is required
      if (!this.productInput.name) {
        bvModalEvt.preventDefault();
        this.formError = false;
        return;
      }
      try {
        await this.$store.dispatch("createItem", {
          item: {
            itemType: "successstories",
            requestConfig: {
              name: this.productInput.name,
              headline: this.productInput.headline,
              description: this.productInput.description,
            },
          },
          file: this.itemImage,
        });
        this.productInput = {};
        this.formError = null;
        this.itemImage = null;
      } catch {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t("error-message.general-error"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text: this.appTitle || this.$t("successstories.title"),
          active: true,
        },
      ];
      this.$store.commit("app/SET_BREADCRUMBS", breadcrumbs);
    },
  },
};
</script>
<style scoped>
.icon-container {
  background-color: white;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 10px;
  padding-top: 10px;
}
.card-container {
  height: 450px;
}
.content-container > img {
  width: 100%;
}
/* TODO: fix responsive with bootstrap, better than media queries!! */
@media only screen and (max-width: 600px) {
  .cards-container {
    margin-left: -2rem;
  }
}
</style>
